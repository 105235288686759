import axios from 'axios'
export default {
    state: {
        bankAccounts: [],
    },
    getters: {
        allBankAccounts: state => state.bankAccounts,
    },
    mutations: {
        setBankAccounts(state, bankAccounts) {
            state.bankAccounts = bankAccounts;
        },
        newBankAccount(state, bankAccount) {
            state.bankAccounts.push(bankAccount);
        },
        updateBankAccount(state, bankAccount) {
            const index = state.bankAccounts.findIndex(
                item => item.id === bankAccount.id
            );

            if (index !== -1) {
                state.bankAccounts.splice(index, 1, bankAccount);
            }
        },
        deleteBankAccount(state, id) {
            const index = state.bankAccounts.findIndex(item => item.id === id);
            state.bankAccounts.splice(index, 1);
        }
    },
    actions: {
        async fetchBankAccounts({ commit }) {
            const response = await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/bank-accounts`);
            commit('setBankAccounts', response.data);
        },

        async addBankAccount({ commit }, newBankAccount) {
            const response = await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/bank-accounts`, newBankAccount);
            commit('newBankAccount', response.data);
        },

        async updateBankAccount({ commit }, updatedBankAccount) {
            const response = await axios.put(
                `${process.env.VUE_APP_KB_API_URL}/api/bank-accounts/${updatedBankAccount.id}`,
                updatedBankAccount
            );
            commit('updateBankAccount', response.data);
        },

        async deleteBankAccount({ commit }, id) {
            await axios.delete(`${process.env.VUE_APP_KB_API_URL}/api/bank-accounts/${id}`);
            commit('deleteBankAccount', id);
        }
    },
}