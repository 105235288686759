import Vue from 'vue'
import Vuex from 'vuex'
import Events from '../store/modules/Events.js'
import Prizes from '../store/modules/Prizes.js'
import Challenges from '../store/modules/Challenges.js'
import BankAccounts from '../store/modules/BankAccounts.js'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        Events,
        Prizes,
        Challenges,
        BankAccounts
    }
})