import axios from "axios";
export default {
    state: {
        prizes: []
    },
    getters: {
        allPrizes: state => state.prizes
    },
    mutations: {
        setPrizes(state, prizes) {
            state.prizes = prizes;
        },
        newPrize(state, prize) {
            state.prizes.unshift(prize);
        },
        updatePrize(state, prize) {
            const index = state.prizes.findIndex(p => p.id === prize.id);
            if (index !== -1) {
                state.prizes.splice(index, 1, prize);
            }
        },
        removePrize(state, id) {
            const index = state.prizes.findIndex(p => p.id === id);
            if (index !== -1) {
                state.prizes.splice(index, 1);
            }
        }
    },
    actions: {
        async fetchPrizes({ commit }) {
            let response = await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/prizes`);
            console.log(response.data)
            commit("setPrizes", response.data);
        },
        async addPrize({ commit }, prize) {
            let response = await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/prizes`, prize, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });
            console.log(response.data)
            commit("newPrize", response.data);
        },
        async updatePrize({ commit }, prize) {
            let response = await axios.put(`${process.env.VUE_APP_KB_API_URL}/api/prizes/${prize.id}`, prize, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });
            console.log(response.data)
            commit("updatePrize", response.data);
        },
        async deletePrize({ commit }, id) {
            await axios.delete(`${process.env.VUE_APP_KB_API_URL}/api/prizes/${id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            commit("removePrize", id);
        }
    }
}