import axios from "axios";
export default {
    state: {
        events: [],
        freeShipment: [
            "Ya",
            "Tidak"
        ],
        images: []
    },
    getters: {
        allEvents: state => state.events,
        allEventImages: state => state.images,
        allFreeShipment: state => state.freeShipment
    },
    mutations: {
        setEvents(state, events) {
            state.events = events;
        },
        setEventImages(state, images) {
            state.images = images;
        },
        newEvent(state, event) {
            state.events.unshift(event);
        },
        updateEvent(state, event) {
            const index = state.events.findIndex(e => e.id === event.id);
            state.events.splice(index, 1, event);
        },
        deleteEvent(state, id) {
            const index = state.events.findIndex(e => e.id === id);
            state.events.splice(index, 1);
        }
    },
    actions: {
        async fetchEvents({
            commit
        }) {
            let response = await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/events`);
            // console.log(response.data)
            commit("setEvents", response.data);
        },

        async createEvent({
            commit
        }, event) {
            let response = await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/events`, event, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });
            console.log(response.data)
            commit("newEvent", response.data);
        },

        async updateEvent({
            commit
        }, event) {
            let response = await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/events/${event.id}`, event, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            commit("updateEvent", response.data);
        },

        async deleteEvent({
            commit
        }, id) {
            await axios.delete(`${process.env.VUE_APP_KB_API_URL}/api/events/${id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            commit("deleteEvent", id);
        },

        async fetchEventImages({commit}){
            let response = await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/events/banners`);
            console.log(response.data)
            commit("setEventImages", response.data);
        }
    }
}