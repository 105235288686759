import axios from "axios";

export default {
    state: {
        challenges: [],
    },
    getters: {
        allChallenges: state => state.challenges,
    },
    mutations: {
        setChallenges(state, challenges) {
            state.challenges = challenges;
        },
        newChallenge(state, challenge) {
            state.challenges.unshift(challenge);
        },
        updateChallenge(state, challenge) {
            const index = state.challenges.findIndex(c => c.id === challenge.id);
            if (index !== -1) {
                state.challenges.splice(index, 1, challenge);
            }
        },
        removeChallenge(state, id) {
            const index = state.challenges.findIndex(c => c.id === id);
            if (index !== -1) {
                state.challenges.splice(index, 1);
            }
        }
    },
    actions: {
        async fetchChallenges({
            commit
        }) {
            let response = await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/challenges`);

            commit("setChallenges", response.data);
        },

        async addChallenge({
            commit
        }, challenge) {
            let response = await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/challenges`, challenge, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            commit("newChallenge", response.data);
        },

        async updateChallenge({
            commit
        }, challenge) {
            let response = await axios.post(`${process.env.VUE_APP_KB_API_URL}/api/challenges/${challenge.id}`, challenge, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            commit("updateChallenge", response.data);
        },

        async deleteChallenge({
            commit
        }, id) {
            await axios.delete(`${process.env.VUE_APP_KB_API_URL}/api/challenges/${id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });

            commit("removeChallenge", id);
        }
    },
}