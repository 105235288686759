import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

/*const routes = [
  {
    path: '/',
    component: () => import('../views/Sorry.vue'),
    meta: {
      title: "Maintenance"
    }
  },
  {
    path: '*',
    component: () => import('../views/Sorry.vue'),
    meta: {
      title: "Maintenance"
    }
  }
] */

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Join Reseller Resmi'
    }
  },
  {
    path: '/promo',
    name: 'Promo',
    beforeEnter: async (to, from, next) => {
      let urlParams = new URLSearchParams(window.location.search)
      
      let event_id = urlParams.get('event_id')
      await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/events/${event_id}`).then(response => {
        // console.log(response.data)
        if(response.data.is_active == 1){
          
          next()
        } else {
          next("/404")
        }
      }).catch(err => {
        console.log(err)
        next("/404")
      })
    },
    component: () => import('../views/Promo.vue'),
    meta: {
      title: 'Promo'
    }
  },
  {
    path: '/waiting-room',
    name: 'WaitingRoom',
    beforeEnter: async (to, from, next) => {
      let urlParams = new URLSearchParams(window.location.search)
      let event_id = urlParams.get('event_id')
      await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/events/${event_id}`).then(response => {
        console.log(response.status)
        if(response.data.is_active == 1){
          next()
        } else {
          next("/404")
        }
      }).catch(err => {
        console.log(err)
        next("/404")
      })
    },
    component: () => import('../views/WaitingRoom.vue'),
    meta: {
      title: 'Waiting Room'
    }
  },
  {
    path: '/invoices',
    name: "Invoices",
    beforeEnter: async (to, from, next) => {
      let token = window.localStorage.getItem('token')
      if(!token){
        next("/404")
      } else {
        next()
      }
    },
    component: () => import('../views/Invoice.vue'),
    meta: {
      title: "Invoices"
    }
  },
  {
    path: '/about',
    name: 'About',

    component: () => import('../views/About.vue')
  },
  {
    path: '/404',
    name: '404',

    component: () => import('../views/404.vue'),
    meta: {
      title: 'Page Not Found'
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/auth/Auth.vue'),
    props: true,
    meta: {
      title: 'User Authentication'
    },
  },
  // {
  //   path: '/member/reseller',
  //   name: 'Reseller',
  //   beforeEnter: async (to, from, next) => {
  //     console.log('Fetching Data...')
  //     let urlParams = new URLSearchParams(window.location.search)
  //     let urlName = urlParams.get('name')
  //     console.log(urlName)
  //     await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/search/${urlName}`).then(response => {
  //       if (response.data.length === 0) {
  //         next('/404')
  //       } else {
  //         next()
  //       }
  //     })
  //   },
  //   component: () => import('../views/Reseller.vue'),
  //   meta: {
  //     title: 'Member Page'
  //   }
  // },
  {
    path: '/v2/member',
    name: 'Member',
    beforeEnter: async (to, from, next) => {
      let urlParams = new URLSearchParams(window.location.search)
      let website_id = urlParams.get('website_id')
      // let local = 'http://127.0.0.1:8000'
      console.log(website_id)
      await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/member/${website_id}/check`).then(response => {
        console.log(response.data)
        if(response.data.status == 'true'){
          next()
        } else {
          next('/404')
        }
      }).catch(err => {
        console.log(err)
        next('/404')
      })
    },
    component: () => import('../views/Member.vue'),
    meta: {
      title: 'Member Page'
    }
  },
  // {
  //   path: '/member/agent',
  //   name: 'Agent',
  //   component: () => import('../views/Agent.vue'),
  //   meta: {
  //     title: 'Member Page'
  //   }
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    props: true,
    meta: {
      title: 'Dashboard'
    },
    children: [{
        path: '/dashboard/main',
        name: 'Main',
        component: () => import('../views/admin/Main.vue'),
        props: true,
        meta: {
          title: 'Halaman Utama'
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/auth/Users.vue'),
        props: true,
        meta: {
          title: 'Manage Users'
        }
      },
      {
        path: '/dashboard/reseller-list',
        name: 'ResellerList',
        component: () => import('../views/admin/Reseller.vue'),
        props: true,
        meta: {
          title: 'Daftar Reseller'
        }
      },
      {
        path: '/dashboard/add-reseller',
        name: 'ManageReseller',
        component: () => import('../views/admin/Manage.vue'),
        props: true,
        meta: {
          title: 'Kelola Data Reseller'
        }
      },
      {
        path: '/dashboard/manage-home-page',
        name: 'ManageHomePage',
        component: () => import('../views/admin/ManageHome.vue'),
        props: true,
        meta: {
          title: 'Kelola Landing Page'
        }
      },
      {
        path: '/dashboard/manage-products',
        name: 'Products',
        component: () => import('../views/admin/Products.vue'),
        props: true,
        meta: {
          title: 'Kelola Produk'
        }
      },
      {
        path: 'manage-event',
        name: 'Events',
        component: () => import('../views/admin/Event.vue'),
        props: true,
        meta: {
          title: 'Kelola Event'
        }
      },

    ],
  },
  {
    path: '/daftar-member',
    name: 'DaftarMember',
    component: () => import('../views/Regencies.vue'),
    props: true,
    meta: {
      title: 'Daftar Member'
    }
  },
  {
    path: '/sorry',
    name: 'Sorry',
    component: () => import('../views/Sorry.vue'),
    meta: {
      title: 'Maintenance'
    }
  },
  {
    path: '*',
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Page Not Found'
    }
  }
] 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token')

  if (to.path === "/dashboard/main" && token == null) {
    next("/auth")
  } else if (to.path === "/dashboard/reseller-list" && token == null) {
    next("/auth")
  } else if (to.path === "/dashboard/add-reseller" && token == null) {
    next("/auth")
  } else if (to.path === "/settings" && token == null) {
    next("/auth")
  } else if (to.path === "/dashboard/manage-home-page" && token == null) {
    next("/auth")
  } else {
    next()
  }
})
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Kedas Beauty`
  next()
})

export default router