<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import {mapActions} from 'vuex'

Vue.use(Vuex);

export const store = new Vuex.Store()
export default {
  name: "App",

  data(){
    return{
      
    }
  },
  methods: {
    
  },
  created(){
    return axios.defaults.withCredentials = true
  }
};
</script>
